import { render, staticRenderFns } from "./DeXuatCTXTDTQGPrint.vue?vue&type=template&id=c48633a6&scoped=true&"
import script from "./DeXuatCTXTDTQGPrint.vue?vue&type=script&lang=js&"
export * from "./DeXuatCTXTDTQGPrint.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c48633a6",
  null
  
)

export default component.exports