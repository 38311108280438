<template>
  <div class="w-100 position-relative pdf-wrapper">
    <CCard class="">
      <div class="modal-body modal-container">
        <div class="pdf-wrapper">
          <div class="pdf-page fs-16">
            <div id="pdf-2" class="pdf-content ml-3 mr-3">
              <div class="text-center fs-16">
                <strong class="bold">Mẫu C.I.1 <br>
                  Văn bản đề xuất thực hiện chương trình xúc tiến đầu tư quốc gia hàng năm</strong>
                <p class="font-italic">(Điểm b, Khoản 2, Điều 92 Nghị định số 31/2021/NĐ-CP)</p>
                <hr>
              </div>
              <div class="row fs-16">
                <div class="col text-center">
                  <p class="font-weight-bold text-uppercase">{{ item.coQuanChuTri }}</p>
                  <hr width="150px" style="border: 1px double">
                  <p class="mt-4 mr-3">Số: {{ item.soVanBan }}</p>
                  <div class="" style="width: 15em; margin-left: 5em">
                    <p class="font-weight align-middle">V/v đề xuất thực hiện chương trình xúc tiến đầu tư quốc gia năm
                      {{ item.nam_ChuongTrinh }}</p>
                  </div>
                </div>
                <div class="col text-center">
                  <span class="text-uppercase font-weight-bold">Cộng hòa xã hội chủ nghĩa Việt Nam</span>
                  <p class="font-weight-bold">Độc lập – Tự do – Hạnh phúc</p>
                  <hr width="180px" style="border: 1px double">
                  <p class="font-weight font-italic" style="float: right">
                    {{ item.diaDiem }}, ngày {{ getDay(item.ngayVanBan) }} tháng {{ getMonth(item.ngayVanBan) }} năm
                    {{ getYear(item.ngayVanBan) }}
                  </p>
                </div>
              </div>
              <div class="text-center">
                <p>Kính gửi: Bộ Kế hoạch và Đầu tư</p>
              </div>
              <div class="ml-3">
                <p style="text-indent: 3em">Căn cứ Điều ... Luật Đầu tư, Điều ... Nghị định số 31/2021/NĐ-CP ngày
                  26/3/2021, Thông tư số .. /2021/TT-BKHĐT ngày ... quy định biểu mẫu thực hiện hoạt động đầu tư tại
                  Việt Nam, đầu tư từ Việt Nam ra nước ngoài và xúc tiến đầu tư và hướng dẫn của Bộ Kế hoạch và Đầu tư
                  tại công văn số.. ngày....,
                  <span class="font-italic">{{ item.tenDonViToChuc }}</span>
                  xin báo cáo và đề xuất một số nội dung sau:
                </p>
                <p class="font-italic" style="text-indent: 3em">(Trong văn bản phải đề cập các nội dung: đánh giá sơ bộ
                  kết quả hoạt động xúc tiến đầu tư năm trước; điều kiện, xu thế và bối cảnh gắn với tiềm năng của vùng,
                  lãnh thổ; sự cần thiết, tác động, ý nghĩa của các hoạt động đối với liên kết vùng. Từ đó, nêu những
                  lĩnh vực, đối tác cần hướng tới...)</p>
                <p style="text-indent: 3em">{{ item.danhGiaHoatDongNamTruoc }}</p>
                <p style="text-indent: 3em">Do đó, cần phải thực hiện các hoạt động đề xuất {{ item.noiDung }} hướng tới
                  <span class="font-italic">{{ item.mucTieu }}</span>.</p>
                <p style="text-indent: 3em"><span class="font-italic">{{ item.coQuanChuTri }}</span> đề nghị Bộ Kế hoạch
                  và Đầu tư đưa {{ itemEmit.soLuong | formatNumber }} hoạt động xúc tiến đầu tư vào chương trình xúc
                  tiến đầu tư quốc gia năm {{ item.nam_ChuongTrinh }} với tổng kinh phí đề xuất
                  {{ itemEmit.tongKinhPhi | formatNumber }} triệu đồng, trong đó:
                  {{ itemEmit.kinhPhi_ChuongTrinhXTDTQG | formatNumber }} triệu đồng từ nguồn kinh phí của Chương trình
                  xúc tiến đầu tư quốc gia.</p>
                <p style="text-indent: 3em"><span class="font-italic">{{ item.coQuanChuTri }}</span> xin gửi kèm theo
                  các tài liệu:</p>
                <p style="text-indent: 7em">1. Biểu tổng hợp đề xuất thực hiện chương trình xúc tiến đầu tư quốc
                  gia;</p>
                <p style="text-indent: 7em">2. Đề án của từng hoạt động xúc tiến đầu tư;</p>
                <p style="text-indent: 7em">3. Bảng dự toán kinh phí của từng hoạt động xúc tiến đầu tư đề xuất.</p>
                <p style="text-indent: 3em">Đề nghị Bộ Kế hoạch và Đầu tư xem xét, tổng hợp các hoạt động nêu trên vào chương trình xúc tiến đầu tư quốc gia năm {{ item.nam_ChuongTrinh }}.</p>
              </div>
              <div class="row">
                <div class="col ml-3">
                  <span class="font-italic font-weight-bold">Nơi nhận:</span> <br>
                  <span class="font-weight">- Như trên;</span> <br>
                  <span class="font-weight">- Lưu: VT,.......</span> <br>
                </div>
                <div class="col">
                  <div class="float-right" style="text-align: center; margin-right: 7em">
                    <span class="font-weight-bold text-uppercase">Thủ trưởng cơ quan chủ trì</span> <br>
                    <span class="font-italic text-align">(Ký, ghi rõ họ tên, chức danh và đóng dấu)</span><br><br><br>
                    <br>
                    <br>
                  </div>
                </div>
              </div>
            </div>
            <div class="print-btn close-button">
              <button class="print-button" @click="print">
                <span class="print-icon"></span></button>
            </div>
          </div>
        </div>
        <!--        <CCardFooter tag="div" class="text-center">-->
        <!--          <CButton color="secondary" class="mr-2" @click="back">Quay lại</CButton>-->
        <!--          <CButton color="danger" @click="isDeleteConfirmationModalOpen = true">Xóa</CButton>-->
        <!--        </CCardFooter>-->
      </div>
    </CCard>
  </div>
</template>

<script>
import { statuses, loaiDonVis, displayLabel } from '@/shared/appConstants'
import { tinhThanhService } from '@/services/tinh-thanh.service'
import { nganhLinhVucService } from '@/services/nganh-linh-vuc.service'
import { quocGiaService } from '@/services/quoc-gia.service'
import moment from 'moment'
import appConfig from '@/shared/appConfig'

export default {
  name: 'DeXuatCTXTDTQGPrint',
  data () {
    return {
      Attachments: [],
      type: 'DeXuatCTXTDTQG',
      optionsTrangThai: statuses,
      optionsLoaiDonVi: loaiDonVis,
      isDeleteConfirmationModalOpen: false,
      optionsTinhThanh: [],
      optionsQuocGia: [],
      optionsNganhLinhVuc: [],
      item: {},
      itemEmit: {
        soLuong: 0,
        tongKinhPhi: 0,
        kinhPhi_ChuongTrinhXTDTQG: 0
      }
    }
  },
  computed: {},
  methods: {
    displayLabel,
    getDownloadURL (url) {
      return url && (url.indexOf('http://') > -1 || url.indexOf('https://') > -1) ? url : appConfig.BASE_HOST + url
    },
    getDate: (time, format = 'YYYY-MM-DD') => time ? moment(time, format).format('DD/MM/YYYY') : '',
    getDay: (time, format = 'YYYY-MM-DD') => time ? moment(time, format).format('DD') : '',
    getMonth: (time, format = 'YYYY-MM-DD') => time ? moment(time, format).format('MM') : '',
    getYear: (time, format = 'YYYY-MM-DD') => time ? moment(time, format).format('YYYY') : '',
    back () {
      this.$router.push({ path: '/thong-bao-to-chuc-hd-xtdt' })
    },
    editItem () {
      this.$router.push({ path: `/thong-bao-to-chuc-hd-xtdt/${this.item.id}/cap-nhat` })
    },
    async confirmDestroy () {
      await this.deleteItem(this.item)
      if (this.responseResult.error) {
        this.$toast.error(this.responseResult.message)
      } else {
        this.$toast.success(this.responseResult.message)
        this.isDeleteConfirmationModalOpen = false
        await this.$router.push({ path: '/thong-bao-to-chuc-hd-xtdt' })
      }
    },
    getLocalStorage () {
      if (localStorage.getItem('de_xuat_ct_xtdt_quoc_gia')) {
        const jsonData = localStorage.getItem('de_xuat_ct_xtdt_quoc_gia')
        this.item = JSON.parse(jsonData).item
        this.itemEmit = JSON.parse(jsonData).itemEmit
      }
    },
    printVanBan () {
      window.print()
    },
    close () {
      const that = this
      this.$store.state.printThongBaoToChuc = {
        ...this.$store.state.printThongBaoToChuc,
        show: false,
        class: 'ms-motion-scaleDownOut'
      }
      setTimeout(() => {
        that.$store.state.printThongBaoToChuc.style = { display: 'none' }
      }, 300)
    },
    print () {
      window.print()
    }
  },
  async created () {
    if (this.$route.params.id) {
      await this.getItem(this.$route.params.id)
      // if (this.item.trangThai === 3) await this.getYKienPheDuyetGanNhat(this.$route.params.id)
    } else {
      await this.getLocalStorage()
    }
    if (this.item.dinhKem) this.Attachments = JSON.parse(this.item.dinhKem)
    this.optionsTinhThanh = await tinhThanhService.getAll()
    this.optionsNganhLinhVuc = await nganhLinhVucService.getAll()
    this.optionsQuocGia = await quocGiaService.getAll()
  }
}
</script>

<style scoped>

</style>
